import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react"
import waves from "../assets/menu-waves.svg"
import mobileWaves from "../assets/menu-waves-small.svg"
import openMenu from '../assets/menu.svg'
import closeMenu from '../assets/menu-close.svg'
import gsap from "gsap";
import useWindowSize from "./hooks/WindowResize";
import Image from "./partials/Image";

const Header = ({site, navigation, doingSplash}) => {
	const comp = useRef()
	const wrapper = useRef()
	const overlay = useRef()
	const navigationRef = useRef()
	const header = useRef()
	const logo = useRef()
	const button = useRef()
	const initialLoad = useRef(true)
	const screenSize = useWindowSize()

	let backgrounds = useMemo(() => {
		if (!navigation) return []
		return navigation.map(menuItem => menuItem.featuredImage)
	}, [navigation])
	let [currentBackground, setBackground] = useState(null)
	let [firstVisit, setFirstVisit] = useState(false)
	let [isOpen, setOpen] = useState(false)

	useEffect(() => {

		if (initialLoad.current) {
			let visited = sessionStorage.getItem('visited')
			if (visited === '1') {
				setFirstVisit(false)
				doingSplash(false)
			} else {
				setFirstVisit(true)
				sessionStorage.setItem('visited', '1')
			}
		}
		gsap.set(navigationRef.current, {
			zIndex: 10,
			duration: 0.7,
			autoAlpha: 0,
			display: "none"
		})
	}, []);

	useEffect(() => {
		if (site)
			setBackground(site.navigationImage)
	}, [site])

	useLayoutEffect(() => {

		if (isOpen)
			wrapper.current.classList.add("open")
		else
			wrapper.current.classList.remove("open")


		let ctx = gsap.context((self) => {
			if (isOpen) {
				gsap.to(navigationRef.current, {
					duration: 0.4,
					autoAlpha: 1,
					display: 'flex',
					zIndex: 10
				})
			} else {
				gsap.set(navigationRef.current, {
					zIndex: 10,
					duration: 0.7,
					autoAlpha: 0,
					display: "none"
				})
			}
		}, header)
		return () => ctx.revert(); // cleanup

	}, [isOpen])


	useLayoutEffect(() => {

		if (!firstVisit) return

		doingSplash(true)
		let logoPositionTop = logo.current.getBoundingClientRect().top

		let ctx = gsap.context(() => {
			gsap.set(button.current, {
				opacity: 0,
				display: "none"
			})
			gsap.set(logo.current, {
				opacity: 0,
				position: 'absolute',
				xPercent: -50,
				yPercent: -50,
				left: "50%",
				top: "50%"
			})
			gsap.set(header.current, {
				background: '#1D1D1D',
				height: '100%',

			})
			gsap.set(wrapper.current, {
				minHeight: '100dvh'
			})
			let timeline = gsap.timeline()
			timeline.to(logo.current, {
				opacity: 1,
				duration: 1,
				delay: 0.5,
				scale: 2
			})
			timeline.to(overlay.current, {
				opacity: 1,
				duration: 0.9
			}, '>-0.8')

			timeline.to(logo.current, {
				ease: "power1.inOut",
				duration: 1.2,
				scale: 1,
				xPercent: 0,
				left: "0%",
				top: logoPositionTop
			})

			timeline.to(overlay.current, {
				opacity: 0,
				duration: 0.3
			})
			timeline.to(header.current, {
				background: '#1D1D1D00',
				duration: 1
			}, '>-0.3')

			timeline.set(header.current, {
				height: 'unset',
			})
			timeline.set([wrapper.current], {
				minHeight: null
			})
			timeline.set([logo.current], {
				position: 'static',
				yPercent: null,
				top: null
			})
			timeline.set(button.current, {
				opacity: 1,
				duration: 0.9
			})


			timeline.play().then(result => {
				setFirstVisit(false)
				doingSplash(false)


			})
		}, header)
		return () => ctx.revert(); // cleanup

	}, [firstVisit])

	useLayoutEffect(() => {
		if (!navigationRef.current) return
		let images = navigationRef.current.querySelectorAll(".menu-background")
		if (!images || images.length === 0) return

		images.forEach(img => {
			if (img.getAttribute('data-original-src') === currentBackground)
				img.classList.add("visible")
			else
				img.classList.remove("visible")
		})

	}, [currentBackground])

	return (
		<>
			<header
				ref={header}
				className="main"
			>
				{firstVisit &&
					<div
						ref={overlay}
						className="overlay"
					></div>
				}
				<div
					ref={wrapper}
					className="wrapper"
				>
					<a
						ref={logo}
						className="logo"
						href="/"
					>
						{site ?
							<img
								src={site.logo}
								width={150}
								alt="Westridge Audio"
							/>
							:
							<div></div>
						}
					</a>

					<button
						ref={button}
						className="toggle"
						onClick={() => setOpen(!isOpen)}
					>
						{
							!isOpen
								?
								<img
									alt="Öppna meny"
									src={openMenu}
								/>
								:
								<img
									alt="Stäng meny"
									src={closeMenu}
								/>
						}
					</button>

					{!firstVisit &&
						<nav
							style={{display: 'none'}}
							ref={navigationRef}
						>
							<div className="menu-overlay">
								{
									site && site.navigationImage
									&&
									<Image
										url={site.navigationImage}
										classes={"menu-background visible"}
										imageHeight={screenSize.height}
										imageWidth={screenSize.width}
									/>
								}
								{backgrounds && backgrounds.map((bg, index) => <Image
										url={bg}
										key={index}
										classes={"menu-background"}
										imageHeight={screenSize.height}
										imageWidth={screenSize.width}
										style={{}}
									/>
								)}
								<img
									className="waves"
									src={screenSize.width > 800 ? waves : mobileWaves}
								/>
							</div>
							<div className="spacer"></div>
							<div className="menu">

								{navigation && navigation.map(menuItem => <a
									key={menuItem.id}
									href={menuItem.url}
									onMouseOverCapture={() => {

										setBackground(menuItem.featuredImage)
									}}
								>
									{menuItem.name}
								</a>)}
							</div>
							<div className="socials">
								{site &&
									site.facebook &&
									<a href={site.facebook}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M14 7.90003H11.6556V6.50003C11.6556 5.77764 11.7114 5.32264 12.6951 5.32264H13.9375V3.09664C13.3329 3.03084 12.725 2.99864 12.1165 3.00004C10.3121 3.00004 8.99525 4.15994 8.99525 6.28933V7.90003H7V10.7L8.99525 10.6993V17H11.6556V10.6979L13.6947 10.6972L14 7.90003Z"
												fill="white"
											/>
										</svg>
									</a>
								}
								{
									site && site.instagram &&
									<a href={site.instagram}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M7.0637 3C4.82572 3 3 4.82572 3 7.0637V12.9363C3 15.1743 4.82572 17 7.0637 17H12.9363C15.1743 17 17 15.1743 17 12.9363V7.0637C17 4.82572 15.1743 3 12.9363 3H7.0637ZM7.0637 4.07692H12.9363C14.5916 4.07692 15.9231 5.40625 15.9231 7.0637V12.9363C15.9231 14.5916 14.5938 15.9231 12.9363 15.9231H7.0637C5.40835 15.9231 4.07692 14.5938 4.07692 12.9363V7.0637C4.07692 5.40835 5.40625 4.07692 7.0637 4.07692ZM14.0385 5.15385C13.5925 5.15385 13.2308 5.51562 13.2308 5.96154C13.2308 6.40745 13.5925 6.76923 14.0385 6.76923C14.4844 6.76923 14.8462 6.40745 14.8462 5.96154C14.8462 5.51562 14.4844 5.15385 14.0385 5.15385ZM10 6.23077C7.92398 6.23077 6.23077 7.92398 6.23077 10C6.23077 12.076 7.92398 13.7692 10 13.7692C12.076 13.7692 13.7692 12.076 13.7692 10C13.7692 7.92398 12.076 6.23077 10 6.23077ZM10 7.30769C11.4934 7.30769 12.6923 8.50661 12.6923 10C12.6923 11.4934 11.4934 12.6923 10 12.6923C8.50661 12.6923 7.30769 11.4934 7.30769 10C7.30769 8.50661 8.50661 7.30769 10 7.30769Z"
												fill="white"
											/>
										</svg>
									</a>
								}
							</div>
						</nav>
					}

				</div>
			</header>

		</>
	)

}

export default Header