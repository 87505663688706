import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import tracking, {initTracking} from './tracking/site-tracking'
let root = null
import './style/site.scss'


document.addEventListener('DOMContentLoaded', () => {
	if (!root) {
		const rootNode = document.getElementById('root')
		root = ReactDOM.createRoot(rootNode);
		const locale = rootNode.getAttribute('data-locale')
		const rootID = rootNode.getAttribute('data-root-id')
		const item = rootNode.getAttribute('data-item')
		root.render(
			<App
				root={rootID}
				locale={locale}
				item={item}
			/>
		);


		initTracking()
	}
})
