import React, { Suspense, useEffect, useLayoutEffect, useRef, useState } from "react"
import { getNavigation, getPageContent, getSiteInfo } from "../api/content"
import Header from "./Header"
import gsap from 'gsap/gsap-core'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Flip } from "gsap/Flip"
import { maybeShowCookieTractor } from "../tracking/site-tracking"

const Footer = React.lazy(() => import("./Footer"))
const Grid = React.lazy(() => import('./Grid'))


function App(props) {
	let [siteData, setSiteData] = useState(null)
	let [navigation, setNavigation] = useState(null)
	let [content, setContent] = useState({})
	let [doingSplash, setSplash] = useState(true)

	const mainOverlay = useRef()

	useEffect(() => {
		if (!doingSplash)
			setTimeout(maybeShowCookieTractor, 3000)
	}, [doingSplash])

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger)
		gsap.registerPlugin(Flip)
		getSiteInfo(props).then(result => {
			setSiteData(result)
		})
		getNavigation(props).then(result => {
			setNavigation(result)
		})
		getPageContent(props).then(result => {
			setContent(result)
		})

	}, [])

	useLayoutEffect(() => {
		if (content == {}) return

		if (content.waveBackground) {
			gsap.to(mainOverlay.current, {
				opacity: 0.1,
				duration: 0.2
			})
		}

	}, [content])

	return (
		<>
			<Suspense fallback={<div></div>}>
				<Header
					site={siteData}
					navigation={navigation}
					doingSplash={setSplash}
				/>
				<main>
					<div
						className="main-overlay"
						ref={mainOverlay}
					>

					</div>
					<div className="grid">
						<Grid {...content.content} {...{ doingSplash: doingSplash, site: siteData }} />
					</div>
				</main>
				<Footer site={siteData} />
			</Suspense>

		</>
	)
}

export default App
