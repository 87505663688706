import axios from "axios";

export const getNavigation = async (props) => {
	try {
		let url = `/${props.locale}/sitedata/navigation`
		if (props.root)
			url += `?rootId=${props.root}`
		let navigation = await axios.get(url)
		
		return navigation.data
		
	} catch (e) {
	
	}
}

export const getSiteInfo = async (props) => {
	try {
		let url = `/${props.locale}/sitedata/site`
		if (props.root)
			url += `?rootId=${props.root}`
		let result = await axios.get(url)
		
		return result.data
		
	} catch (e) {
	
	}
}

export const getPageContent = async (props) => {
	try {
		let url = `/umbraco/delivery/api/v1/content/item/${props.item}`
		if (props.root)
			url += `?rootId=${props.root}`
		let result = await axios.get(url)
		
		return result.data.properties
	} catch (e) {
	
	}
}