const sendRequest = (eventName, eventCategory, eventLabel, eventValue) => {

	if (typeof gtag === "function")
		if (eventValue !== undefined)
			gtag('event', eventName, {
				'event_category': eventCategory,
				'event_label': eventLabel,
				'value': eventValue
			})
		else
			gtag('event', eventName, {
				'event_category': eventCategory,
				'event_label': eventLabel
			})
	else if (typeof ga === "function")
		ga('send', {
			hitType: 'event',
			eventCategory: eventCategory,
			eventAction: eventName,
			eventLabel: label
		})


	if (typeof fbq === "function")
		fbq('trackCustom', eventName)


	if (window.uetq != undefined)
		window.uetq.push('event', eventName, {})


}

const getSiteTitle = () => {
	let title = document.title.split(" | ")

	//wrong seperator?
	if (title.length == 1) {
		title = document.title.split(" - ")
	}
	return title[0]
}

const contactFormsTrack = () => {
	const forms = document.querySelectorAll("form")
	forms.forEach(form => {
		form.addEventListener('submit', function () {
			sendRequest('kontaktformulär', 'kontaktformulär', '', '')
		})
	})

}

const linksTracking = () => {
	document.addEventListener('click', function (e) {
		const target = e.target
		let link = undefined
		if (target.nodeName == 'A')
			link = target
		else if (target.closest('a') != undefined)
			link = target.closest('a')
		else
			return

		const href = link.href

		let event = ''
		let label = ''
		if (href.includes('tel:')) {
			event = 'telefonsamtal'
			label = href.replace('tel:', '')
		} else if (href.includes('mailto:')) {
			event = 'mail'
			label = href.replace('mailto:', '')
		}

		if (event != '') {

			e.preventDefault()
			e.stopPropagation()
			sendRequest(event, event, label)

			setTimeout(function () {
				window.location = href
			}, 300)
		}
	})
}

const dataLabelTracking = () => {
	document.addEventListener('click', function (e) {
		const target = e.target
		let event = ''
		if (target.getAttribute('data-label'))
			event = target.getAttribute('data-label')
		else if (target.closest('*[data-label]'))
			event = target.closest('*[data-label]').getAttribute('data-label')

		if (event != '')
			sendRequest(event)
	})
}


const initLinkedIn = () => {

	if (linkedin == undefined) return

	_linkedin_partner_id = linkedin
	window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
	window._linkedin_data_partner_ids.push(_linkedin_partner_id);

	(function () {
		var s = document.getElementsByTagName("script")[0]
		var b = document.createElement("script")
		b.type = "text/javascript";
		b.async = true
		b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"
		s.parentNode.insertBefore(b, s)
	})()
}

/**
 * LinkedIn does not provide a revoking mechanism. Nuke the cookies
 */
const removeLinkedIn = () => {
	const cookies = [
		'AnalyticsSyncHistory',
		'UserMatchHistory',
		'bcookie',
		'lidc',
		'bscookie',
		'li_gc',
		'ln_or'
	]

	cookies.forEach(cookie => {
		if (getCookie(cookie)) {
			document.cookie = cookie + '=; Max-Age=-99999999;'
		}
	})
}

const grantStatistical = () => {
	if (typeof gtag === 'function')
		gtag('consent', 'update', {
			'analytics_storage': 'granted'
		})
}

const grantMarketing = () => {
	if (typeof gtag === 'function')
		gtag('consent', 'update', {
			'ad_storage': 'granted'
		})

	if (window.uetq != undefined)
		window.uetq.push('consent', 'update', {
			'ad_storage': 'granted'
		})


	if (typeof fbq === "function")
		fbq('consent', 'grant')

}

const revokeStatistical = () => {
	if (typeof gtag === 'function')
		gtag('consent', 'update', {
			'analytics_storage': 'denied'
		})

	removeLinkedIn()
}

const revokeMarketing = () => {
	if (typeof gtag === 'function')
		gtag('consent', 'update', {
			'ad_storage': 'denied'
		})

	if (window.uetq != undefined)
		window.uetq.push('consent', 'update', {
			'ad_storage': 'denied'
		})


	if (typeof fbq === "function")
		fbq('consent', 'revoke')

	removeLinkedIn()

}

export const maybeShowCookieTractor = () => {
	if (!cookieTractor.consent.contains("necessary"))
		cookieTractor.openConsentSettings()
}

export const initTracking = () => {
	if (!cookieTractor) return;

	dataLabelTracking()
	linksTracking()
	contactFormsTrack()

	if (cookieTractor.consentGivenFor('statistical'))
		grantStatistical()

	if (cookieTractor.consentGivenFor('marketing'))
		grantMarketing()


	document.addEventListener('CookieConsentGiven', () => {
		const statistical = cookieTractor.consentGivenFor('statistical')
		const marketing = cookieTractor.consentGivenFor('marketing')
		if (statistical)
			grantStatistical()

		if (marketing)
			grantMarketing()

		if (marketing && statistical)
			initLinkedIn()
		else
			removeLinkedIn()

	})

	document.addEventListener('CookieConsentRevoked', () => {
		if (!cookieTractor.consentGivenFor('statistical'))
			revokeStatistical()

		if (!cookieTractor.consentGivenFor('marketing'))
			revokeMarketing()
	})
}

