import React, {Suspense, useEffect, useRef, useState} from "react"

const Image = (props) => {
	const img = useRef()
	const supportsWebp = useRef(true)
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		supportsWebp.current = (function () {
			const webP = new Image();
			webP.onload = WebP.onerror = function () {
				callback(webP.height === 2);
			};
			webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
		})
	}, [])
	const createUrl = () => {

		let url = props.url + '?'
		let atts = []

		if (props.srcWidth)
			atts.push(`width=${props.srcWidth * 1.2}`)
		else if (props.imageWidth)
			atts.push(`width=${props.imageWidth * 1.2}`)

		if (props.srcHeight)
			atts.push(`height=${props.srcHeight * 1.2}`)
		else if (props.imageHeight)
			atts.push(`height=${props.imageHeight * 1.2}`)

		if (props.focalPoint)
			atts.push(`rxy=${props.focalPoint.left},${props.focalPoint.top}`)

		if (supportsWebp.current)
			atts.push("format=webp")

		return url + atts.join("&")

	}

	return (
		<Suspense
			fallback={<div
				className="placeholder"
				style={{height: props.imageHeight, width: props.imageWidth}}
			></div>}
		>
			{!loaded &&
				<div
					className="placeholder"
					style={{
						height: props.placeholderHeight ?? props.imageHeight,
						maxHeight: props.placeHolderHeight ?? props.imageHeight,
						maxWidth: props.imageWidth,

					}}
				>
					<svg
						width="135"
						height="140"
						viewBox="0 0 135 140"
						xmlns="http://www.w3.org/2000/svg"
						fill="#fff"
					>
						<rect
							y="10"
							width="15"
							height="120"
							rx="6"
						>
							<animate
								attributeName="height"
								begin="0.5s"
								dur="1s"
								values="120;110;100;90;80;70;60;50;40;140;120"
								calcMode="linear"
								repeatCount="indefinite"
							/>
							<animate
								attributeName="y"
								begin="0.5s"
								dur="1s"
								values="10;15;20;25;30;35;40;45;50;0;10"
								calcMode="linear"
								repeatCount="indefinite"
							/>
						</rect>
						<rect
							x="30"
							y="10"
							width="15"
							height="120"
							rx="6"
						>
							<animate
								attributeName="height"
								begin="0.25s"
								dur="1s"
								values="120;110;100;90;80;70;60;50;40;140;120"
								calcMode="linear"
								repeatCount="indefinite"
							/>
							<animate
								attributeName="y"
								begin="0.25s"
								dur="1s"
								values="10;15;20;25;30;35;40;45;50;0;10"
								calcMode="linear"
								repeatCount="indefinite"
							/>
						</rect>
						<rect
							x="60"
							width="15"
							height="140"
							rx="6"
						>
							<animate
								attributeName="height"
								begin="0s"
								dur="1s"
								values="120;110;100;90;80;70;60;50;40;140;120"
								calcMode="linear"
								repeatCount="indefinite"
							/>
							<animate
								attributeName="y"
								begin="0s"
								dur="1s"
								values="10;15;20;25;30;35;40;45;50;0;10"
								calcMode="linear"
								repeatCount="indefinite"
							/>
						</rect>
						<rect
							x="90"
							y="10"
							width="15"
							height="120"
							rx="6"
						>
							<animate
								attributeName="height"
								begin="0.25s"
								dur="1s"
								values="120;110;100;90;80;70;60;50;40;140;120"
								calcMode="linear"
								repeatCount="indefinite"
							/>
							<animate
								attributeName="y"
								begin="0.25s"
								dur="1s"
								values="10;15;20;25;30;35;40;45;50;0;10"
								calcMode="linear"
								repeatCount="indefinite"
							/>
						</rect>
						<rect
							x="120"
							y="10"
							width="15"
							height="120"
							rx="6"
						>
							<animate
								attributeName="height"
								begin="0.5s"
								dur="1s"
								values="120;110;100;90;80;70;60;50;40;140;120"
								calcMode="linear"
								repeatCount="indefinite"
							/>
							<animate
								attributeName="y"
								begin="0.5s"
								dur="1s"
								values="10;15;20;25;30;35;40;45;50;0;10"
								calcMode="linear"
								repeatCount="indefinite"
							/>
						</rect>
					</svg>
				</div>
			}
			<img
				data-original-src={props.url}
				height={props.imageHeight ?? 'auto'}
				width={props.imageWidth || window.innerWidth}
				src={createUrl()}
				alt={props.name}
				className={props.classes}
				onLoad={() => setLoaded(true)}
				style={props.style ? props.style : {
					display: loaded ? 'block' : 'none',
					opacity: loaded ? 1 : 0,
				}}
			/>
		</Suspense>
	)

}

export default Image